import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Icon from '../../../components/Icon';
import MultiVideoPlayer from '../../../components/MultiVideoPlayer';

const HowNatureInspires3MScience = () => (
  <Layout
    title="How Nature Inspires 3M Science"
    className="science-starters virtual-field-trip has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../virtual-field-trips">Virtual Field Trips</Link>
          </li>
          <li className="breadcrumb active">How Nature Inspires 3M Science</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">How Nature Inspires 3M Science</h1>
            {/* Video Container */}
            <div className="video-container__with-pager multivideo">
              <Link to="../inside-the-world-of-science" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <MultiVideoPlayer
                videos={[
                  {
                    guidSrc: '8b2c5b37-1ae9-4b24-8652-2412fc33e0a5',
                    poster: 'VFT-How-Nature-Inspires-3M-Full.jpg',
                    title: 'Full VFT: How Nature Inspires 3M Science',
                    className: 'de-vid',
                  },
                  {
                    src: 'https://cep-de-primaryusprod-use2.streaming.media.azure.net/b6b54e86-a0a5-4048-8b73-0d5e0fa0e8e5/Chapter1Welcomesource.ism/manifest',
                    poster: 'VFT-how-nature-inspires-3M-science-chapter-1.jpg',
                    title: 'Chapter 1: Welcome',
                    className: 'azure',
                  },
                  {
                    src: 'https://cep-de-primaryusprod-use2.streaming.media.azure.net/1cb551af-bd20-4675-a294-f30bc93b640b/Chapter23ME284A2ThinsulateE284A2.ism/manifest',
                    poster: 'VFT-how-nature-inspires-3M-science-chapter-2.jpg',
                    title: 'Chapter 2: 3M™ Thinsulate™ Featherless Insulation',
                    className: 'azure',
                  },
                  {
                    src: 'https://cep-de-primaryusprod-use2.streaming.media.azure.net/63f66d98-34e1-476a-b18a-cdf55b8eb952/Chapter3Adhesivessource.ism/manifest',
                    poster: 'VFT-how-nature-inspires-3M-science-chapter-3.jpg',
                    title: 'Chapter 3: 3M™ Adhesives',
                    className: 'azure',
                  },
                  {
                    src: 'https://cep-de-primaryusprod-use2.streaming.media.azure.net/2261fbe1-00ab-4758-9e40-e023f6f12f3c/Chapter4E280933ME284A2FoodSafety.ism/manifest',
                    poster: 'VFT-how-nature-inspires-3M-science-chapter-4.jpg',
                    title: 'Chapter 4: 3M™ Food Safety',
                    className: 'azure',
                  },
                  {
                    src: 'https://cep-de-primaryusprod-use2.streaming.media.azure.net/77c6df50-2ed9-432f-a59f-0c43f7974e3e/Chapter5Meetthe3ME284A2Scientist.ism/manifest',
                    poster: 'VFT-how-nature-inspires-3M-science-chapter-5.jpg',
                    title: 'Chapter 5: Meet the 3M™ Scientist',
                    className: 'azure',
                  },
                ]}
              />
              <Link to="../inside-the-world-of-science" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row>
          <Column size={8} offset={1}>
            <h2>About</h2>
            <p>
              Go inside a 3M innovation center to learn how a wide variety of
              passionate scientists work together to create life-changing
              products. Students will investigate how scientists use biomimicry
              to harness the complexity of the natural world to solve problems
              and inspire new innovations. Transport students to 3M World
              Headquarters and explore a variety of exciting careers in science.
            </p>
            <ul>
              <li>
                Can you think of a natural phenomenon that would provide good
                inspiration for biomimicry?
              </li>
              <li>
                Did you know ultrasound was originally created by applying
                biomimicry to dolphins?
              </li>
              <li>
                Would you rather experiment in the lab, research in the field,
                or work on product development?
              </li>
              <li>What animals have taught us the most through biomimicry?</li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default HowNatureInspires3MScience;
